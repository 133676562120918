import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { HOME_URL } from 'constants/urls';

export type LinkProps = NextLinkProps &
  JSX.IntrinsicElements['a'] & {
    newTab?: boolean;
    stretchParent?: boolean;
  };

const Link: React.FC<LinkProps> = ({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  newTab,
  color = '#00000000', // 0 opacity to prevent override
  stretchParent,
  ...anchorProps
}) => {
  let { pathname } = useRouter();
  if (!pathname.endsWith('/')) {
    pathname += '/';
  }
  const isActive = pathname === href;
  const isPartiallyActive = href !== HOME_URL && pathname.startsWith(href);

  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
      target={newTab ? '_blank' : '_self'}
      aria-current={
        isActive || isPartiallyActive ? anchorProps['aria-current'] || 'page' : undefined
      }
      style={stretchParent ? { color, width: '100%', height: '100%' } : { color }}
    >
      {children}
    </NextLink>
  );
};

export default Link;
