import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { LOGO_ALT, LOGO_DEFAULT_SIZE, LOGO_IMAGE } from 'constants/images';

interface LogoProps {
  height?: number;
  width?: number;
  boxShadowColor?: string;
}

const OuterLogoDiv = styled.div<LogoProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border-radius: 50%;
  ${(props) =>
    props.boxShadowColor
      ? `box-shadow: 0 0px ${0.1 * (props.height || LOGO_DEFAULT_SIZE.height)}px 0px ${
          props.boxShadowColor
        };`
      : ''}
  backdrop-filter: blur(5px);
  z-index: 1000;
`;

const makeImageStyle = (): React.CSSProperties => ({
  borderRadius: '50%',
  overflow: 'hidden',
});

const Logo = ({
  height = LOGO_DEFAULT_SIZE.height,
  width = LOGO_DEFAULT_SIZE.width,
  ...rest
}: LogoProps) => {
  return (
    <OuterLogoDiv height={height} width={width} {...rest}>
      <Image
        priority
        width={width}
        height={height}
        alt={LOGO_ALT}
        src={LOGO_IMAGE}
        style={makeImageStyle()}
        loading="eager"
      />
    </OuterLogoDiv>
  );
};

export default Logo;
