import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import React from 'react';
import styled from 'styled-components';
import Link from 'components/common/link';
import {
  FACEBOOK_PROFILE_URL,
  LINKEDIN_PROFILE_URL,
  INSTAGRAM_PROFILE_URL,
  GITHUB_PROFILE_URL,
  AIFLOW_URL,
  FACEBOOK_LINK_ID,
  LINKEDIN_LINK_ID,
  GITHUB_LINK_ID,
  INSTAGRAM_LINK_ID,
  AIFLOW_LINK_ID,
} from 'constants/urls';
import { mq } from 'utils/responsive';
import { theme } from 'utils/theme';
import AiFlow from '../../../../../public/images/icons/Aiflow.svg';

interface SocialMediaIconsProps {
  baseHeight?: number;
  margin?: string;
  color?: string;
}

const SocialMediaIconsDiv = styled.div<Pick<SocialMediaIconsProps, 'margin' | 'color'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0;

  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  > * {
    color: ${(props) => props.color || theme.colors.white};

    margin: 0 5px;
    ${mq.ltsm`
        margin: 0 2px;
    `}
  }
`;

const SOCIAL_MEDIA_ICON_SCALE: Record<string, number> = {
  facebook: 1.15,
  instagram: 1.2,
  github: 1,
  linkedin: 1.2,
  aiflow: 0.8,
};

const SocialMediaIcons = ({ baseHeight = 30, color, ...rest }: SocialMediaIconsProps) => {
  return (
    <SocialMediaIconsDiv color={color} {...rest}>
      <Link target="_blank" href={FACEBOOK_PROFILE_URL} id={FACEBOOK_LINK_ID}>
        <FacebookIcon
          style={{
            color,
            fill: color,
            width: 'auto',
            height: `${baseHeight * SOCIAL_MEDIA_ICON_SCALE.facebook}px`,
          }}
        />
      </Link>
      <Link target="_blank" href={LINKEDIN_PROFILE_URL} id={LINKEDIN_LINK_ID}>
        <LinkedInIcon
          style={{
            color,
            fill: color,
            width: 'auto',
            height: `${baseHeight * SOCIAL_MEDIA_ICON_SCALE.linkedin}px`,
          }}
        />
      </Link>
      <Link target="_blank" href={GITHUB_PROFILE_URL} id={GITHUB_LINK_ID}>
        <GitHubIcon
          style={{
            color,
            fill: color,
            width: 'auto',
            height: `${baseHeight * SOCIAL_MEDIA_ICON_SCALE.github}px`,
          }}
        />
      </Link>
      <Link target="_blank" href={INSTAGRAM_PROFILE_URL} id={INSTAGRAM_LINK_ID}>
        <InstagramIcon
          style={{
            color,
            fill: color,
            width: 'auto',
            height: `${baseHeight * SOCIAL_MEDIA_ICON_SCALE.instagram}px`,
          }}
        />
      </Link>
      <Link target="_blank" href={AIFLOW_URL} id={AIFLOW_LINK_ID}>
        <AiFlow
          color={color || 'white'}
          fill={color || 'white'}
          style={{
            width: 'auto',
            height: baseHeight * SOCIAL_MEDIA_ICON_SCALE.aiflow,
          }}
        />
      </Link>
    </SocialMediaIconsDiv>
  );
};

export default SocialMediaIcons;
